import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
export const segmentAnalytics = Analytics({
  app: 'donrep-website',
  plugins: [
    segmentPlugin({
      writeKey: 'wJAlFeHrXSU7jOI3hmjPemLj3BIFOZfR'
    })
  ]
})
export const segmentEvent=(section,nameEvent)=>{
  segmentAnalytics.track(nameEvent, {
    section:section,
    platfrom:"website"
  })
}
