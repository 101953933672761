import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";

import Sellers from "./components/pages/Sellers";
import Delivery from "./components/pages/Deliveris";
import TagManager from "react-gtm-module";
import { lazy,Suspense } from "react";
import { segmentEvent } from "./config/segment";
AOS.init();
const Home=lazy(()=>import("./components/pages/Home"))
const AboutDonRep=lazy(()=>import("./components/pages/AboutDonRep"));
const TermsConditions=lazy(()=>import("./components/pages/TermsConditions"))
const PrivacityPolicity=lazy(()=>import("./components/pages/PrivacyPolicy"))

const tagManagerArgs = {
  gtmId: 'GTM-TQKTBGP'
}

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Suspense fallback={<div id="preloader"></div>}>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/sobre-nosotros" component={AboutDonRep} />
          <Route path="/tiempo-envios" component={Delivery} />
          <Route path="/terminos-condiciones" component={TermsConditions} />
          <Route path="/politica-privacidad" component={PrivacityPolicity} />
          <Route path="/vendedores" component={Sellers} />
          <Redirect to="/"/>
        </Switch>
        <a
          onClick={()=>segmentEvent('home-fixed',`click-whatsapp`)}
          href="https://api.whatsapp.com/send?phone=573017592646&text="
          rel="noreferrer"
          className="whatsapp"
          target="_blank"
        >
          <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
      </Router>
    </Suspense>
  );
}

export default App;
