import ReactGA from "react-ga";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { segmentEvent } from "../../config/segment";

ReactGA.initialize("UA-194590564-1");

const shiping = [
  "inter_rapidisimo",
  "servientrega",
  "envia",
  "tcc",
  "coordinadora",
  "redetrans",
];
const Delivery = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  
  return (
    <>
      <section id="shipping" className="details py-5">
        <div className="container ">
          <div className="row px-5 mt-5 justify-content-center align-items-center">
            <div className="col-md-6 col-sm-12" data-aos="fade-up">
              <div className="section-title m-0" data-aos="fade-up">
                <p>Envíos a domicilio</p>
              </div>
              <p className="text-justify mb-3 text-description">
                Una vez realizada tu compra, haremos el envío de tu pedido al
                lugar donde lo necesites!
              </p>
              <ul className="m-0 p-0 mb-5 text-description"  >
                <li>
                  <i className="bi bi-check"></i>
                  <strong>Bogotá, Medellín</strong>: Mismo día o próximo día.
                </li>
                <li>
                  <i className="bi bi-check"></i>{" "}
                  <strong>Ciudades principales:</strong> 2 días
                </li>
                <li>
                  <i className="bi bi-check"></i>{" "}
                  <strong>Ciudades remotas:</strong> 3 días
                </li>
              </ul>
               <lottie-player
                style={{ height: "200px" }}
                src="/assets/animations/shiping.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
            <div
              className="col-md-6 col-sm-12  mb-0 containerPayments d-none d-md-flex"
              data-aos="fade-right"
            >
              {shiping.map((img) => (
                <div  key={Math.random()}className="cardShiping">
                  <img  onClick={()=>segmentEvent('deliveries',`click-${img}`)} loading="lazy" title="donrep-img"src={`assets/img/payments/${img}.png`} alt={img}></img>
                </div>
              ))}
            </div>
            <div className=" col-md-6 col-sm-12 d-block pt-5 d-md-none">
              <h2 style={{color:"#1C4328"}}><strong>Transportadoras</strong></h2>
              {shiping.map((img) => (
                <img  key={Math.random()}onClick={()=>segmentEvent('deliveries',`click-${img}`)}  loading="lazy" title="donrep-img"src={`assets/img/payments/${img}.png`} width="100px" alt="payments"></img>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(Delivery);
